import React from 'react'
import ReactDOM from 'react-dom'
import {Global, css} from '@emotion/core'
import styled from '@emotion/styled'

import Header from './components/header'
import Hero from './components/hero'
import Releases from './components/releases'
import About from './components/about'
import Contact from './components/contact'
import Footer from './components/footer'

const App = () => {
  return(
    <SiteContainer>
      <Global styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        body {
          line-height: 1.33;
        }

        h1, h2, h3, h4, h5 {
          width: 100%;
          margin-bottom: 1em;
        }

        p {
          margin-bottom: 1rem;
        }

        img {
          max-width: 100%;
          display: block;
        }

        a {
          text-decoration: none;
          outline: none;
        }
      `} />

      <Header />

      <Main>
        <Hero />
        <Releases />
        <About />
        <Contact />
      </Main>

      <Footer />
    </SiteContainer>
  )
}

const SiteContainer = styled.div`
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  flex: 1;
`

ReactDOM.render(<App/>,
  document.querySelector('#root'))
import React from 'react'
import styled from '@emotion/styled'

import Content from './partials/content'

import amITooLoyal from '../img/am-i-too-loyal.jpg'

const releases = [{
  title: 'Am I Too Loyal?',
  year: '2020',
  description: 
    <p>"Am I Too Loyal?" is Broken Stairs's debut album.  The album is broken down into three equal-length parts starting with an introduction inspired by Billow Observatory among many others, "Beating Steps" that gets its name from an important architectural feature of David's housing during his college years learning deep listening from Pauline Oliveros, and ending with a third part that is a signature crescendo.  This first extended play introduces Broken Stairs's unique tonality and pacing as an ambient composer</p>,
  image: amITooLoyal
}]

const Releases = () => {
  return (
    <ReleasesSection id="releases">
      <Content>
        <h2>Releases</h2>

        {releases.map(({title, year, description, image}, index) => (
          <Release key={index}>
            <Column>
              <img src={image} alt={title} />
            </Column>
            <Column>
              <h3>{title}</h3>
              <h4>{year}</h4>

              {description}

              <Button href="#">Purchase</Button>

              <Button href="#">Stream</Button>
            </Column>
          </Release>
        ))}
      </Content>
    </ReleasesSection>
  )
}

export default Releases

const ReleasesSection = styled.section`
  background: black;
  color: white;

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
`

const Release = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0.5rem;
  }

  h4 {
    font-style: italic;
    margin-bottom: 1rem;
  }
`

const Column = styled.div`
  width: calc(50% - 1.5rem);
`

const Button = styled.a`
  display: inline-block;
  margin: 1rem;
  padding: 1rem;

  color: white;
  border: 1px solid white;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover,
  &:focus {
    background: white;
    color: black;
  }
`
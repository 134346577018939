import React from 'react'

import Content from './partials/content'

const About = () => {
  return (
    <section id="about">
      <Content>
        <h2>About</h2>

        <p>Broken Stairs is David Rhoderick's moniker for ambient, minimal, new age, electronic music.  Influenced by Eluvium, Billow Observatory, The Sight Below, Deaf Center, Heathered Pearls, Brand New, and Radiohead, Broken Stairs chases a concept of ambient soundscapes absent from the genre today.  Broken Stairs pushes creativity by varying lengths of tracks, number of tracks, and instrumentation across releases</p>

        <p>David started playing piano around the age of 5 and picked up guitar at 8.  Moving from classical to alternative rock, he joined a band in high school and played as part of a 4-piece band and an acoustic duo.  He worked with Pauline Oliveros at Rensselaer Polytechnic Institute and started experimenting with various instrumentation, from guitar pedals and detuned guitars to programmed instruments in Max/MSP, to perform free improvisational music.  After graduating from The School of Visual Arts, David moved to Novi Sad, Serbia, played in another alternative rock band and is now composing and producing ambient music as Broken Stairs.</p>
      </Content>
    </section>
  )
}

export default About


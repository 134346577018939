import React from 'react'
import styled from '@emotion/styled'

import Content from './partials/content'

const Footer = () => {
  return (
    <footer>
      <FooterContent>
        <small>&copy; {new Date().getFullYear()} David Rhoderick, all rights reserved.</small>
      </FooterContent>
    </footer>
  )
}

export default Footer

const FooterContent = styled(Content)`
  padding: 1rem;

  small {
    width: 100%;
    text-align: right;
  }
`
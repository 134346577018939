import React from 'react'
import styled from '@emotion/styled'
import emailjs from 'emailjs-com'

import Content from './partials/content'

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('gmail', 'template_jhRl7CfD', e.target, 'user_A8xPmGykcUnrwP5EvmPgI')
      .then((result) => {
        console.log(result.text)
      }, (error) => {
        console.error(error.text)
      });
  }
  return (
    <ContactSection id="contact">
      <Content>
        <h2>Get in touch</h2>

        <ContactForm className="contact-form" onSubmit={sendEmail}>
          <label htmlFor="full_name">Full name</label>
          <input type="text" name="full_name" required/>

          <label htmlFor="email">Email</label>
          <input type="email" name="email" required/>

          <label htmlFor="subject">Subject</label>
          <input type="text" name="subject" required/>
          
          <label htmlFor="message">Message</label>
          <textarea name="message" required/>
          
          <input type="submit" value="Send" />
        </ContactForm>
      </Content>
    </ContactSection>
  )
}

export default Contact

const ContactSection = styled.section`
  background: black;
  color: white;
`

const ContactForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  label {
    margin-bottom: 1rem;
  }

  input, textarea {
    border: 1px solid white;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background: black;
    color: white;
  }

  input[type="submit"] {
    cursor: pointer;
    align-self: center;
    padding: 1rem;

    &:hover,
    &:focus {
      background: white;
      color: black;
    }
  }
`
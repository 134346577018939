import React from 'react'
import styled from '@emotion/styled'

import Content from './partials/content'

const Hero = () => {
  return (
    <HeroSection id="hero">
      <HeroContent>
        <h1>Broken Stairs</h1>
        <h2>Ambient / Downtempo</h2>
        <Button href="#releases">Latest Releases</Button>
        <Button href="#contact">Get in Touch</Button>
      </HeroContent>
    </HeroSection>
  )
}

export default Hero

const HeroSection = styled.section`
  padding: 25% 0 15%;
`

const HeroContent = styled(Content)`
  justify-content: end;
  h1, h2 {
    width: 100%;
    text-align: right;
  }
`

const Button = styled.a`
  display: inline-block;
  border: 1px black solid;
  color: black;
  margin: 1rem;
  padding: 1rem;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    background: black;
    color: white;
  }
`
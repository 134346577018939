import React from 'react'
import styled from '@emotion/styled'

import Content from './partials/content'

const Header = () => {
  return (
    <StyledHeader>
      <HeaderContent>
        <a href="#hero">Broken Stairs</a>

        <nav>
          <a href="#releases">Releases</a>
          <a href="#about">About</a>
          <a href="#contact">Get in touch</a>
        </nav>
      </HeaderContent>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  position: fixed;
  background: black;
  width: 100%;
`

const HeaderContent = styled(Content)`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: white;

    &:hover,
    &:focus {
      border-bottom: 1px solid white;
    }
  }

  nav a {
    margin: 0 1rem;
  }
`